@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@300;400;500;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  @apply antialiased;
}

html, body, #root {
  height: 100%;
  background-color: hsl(var(--b2));
  color: hsl(var(--bc));
}

body {
  font-family: 'Roboto', sans-serif;
}

input[type="radio"]:checked + label span {
  background-color: #718096; /* bg-gray-600 */
  /* box-shadow: 0px 0px 0px 1px white inset; */
}

input[type="radio"]:checked + label{
  /* color: #3490DC; //text-blue */
}

.invalid-shadow:focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.invalid-shadow-focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.Toastify__toast.Toastify__toast--success,
.Toastify__toast.Toastify__toast--info,
.Toastify__toast.Toastify__toast--warn,
.Toastify__toast.Toastify__toast--error {
  border-radius: 5px;
}

input:checked + svg {
  display: block;
}


.ellipsis-3lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
}

.translate-center {
  transform: translate(-50%,0);
}

.blur {
  filter: blur(8px);
}